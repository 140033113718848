$env: production;
@mixin browser-highlight($size: null, $style: null, $color: null) {
  // reference: https://ghinda.net/article/mimic-native-focus-css/

  @if not $style {
    outline-style: solid;

    @media (-webkit-min-device-pixel-ratio: 0) {
      outline-style: auto;
    }
  } @else {
    outline-style: $style;
  }

  @if not $size {
    outline-width: 2px;
  } @else {
    outline-width: $size;
  }

  @if not $color {
    outline-color: Highlight;

    @media (-webkit-min-device-pixel-ratio: 0) {
      outline-color: -webkit-focus-ring-color;
    }
  } @else {
    outline-color: $color;
  }
}

@mixin respond-above($breakpoint) {
  @media (min-width: $breakpoint) {
    @content;
  }
}

@mixin respond-above-x-or-y($breakpointX, $breakpointY) {
  @media (min-width: $breakpointX), (min-height: $breakpointY) {
    @content;
  }
}

@mixin respond-above-x-and-y($breakpointX, $breakpointY) {
  @media (min-width: $breakpointX) and (min-height: $breakpointY) {
    @content;
  }
}


//
//  RESPOND BELOW
//––––––––––––––––––––––––––––––––––––––––––––––––––

@mixin respond-below($breakpoint) {
  @media (max-width: ($breakpoint - 1)) {
    @content;
  }
}

@mixin respond-below-x-or-y($breakpointX, $breakpointY) {
  @media (max-width: $breakpointX - 1), (max-height: $breakpointY - 1) {
    @content;
  }
}

@mixin respond-below-x-and-y($breakpointX, $breakpointY) {
  @media (max-width: $breakpointX - 1) and (max-height: $breakpointY - 1) {
    @content;
  }
}


//
//  RESPOND BETWEEN
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include respond-between(sm, md) {}
@mixin respond-between($lower, $upper) {
  // Write the media query.
  @media (min-width: $lower) and (max-width: ($upper - 1)) {
    @content;
  }
}

@mixin modal-header {
  font-size: 1.5rem;
  line-height: 126%;
  /* or 30px */
  text-align: center;
  letter-spacing: -0.01em;
  color: #333333;
  margin: 0 auto;
}

@mixin confirm-btn {
  background-color: #2F2F30;
  color: #ffffff;
  border:1.5px solid #2F2F30;
}

@mixin confirm-btn-hover {
  background-color: #383c44;
  color: #ffffff;
  border-color: #2F2F30;
}

@mixin added-to-cart {
  text-decoration: none;
  color: $green !important;
  font-weight: 600;
  svg { height: 1.25em; }

  @keyframes dropOut {
    from {
      transform: translateY(0%);
      opacity: 1;
    }
    to {
      transform: translateY(100%);
      opacity: 0;
    }
  }
  @keyframes dropIn {
    from {
      transform: translateY(-100%);
      opacity: 0;
    }
    to {
      transform: translateY(0%);
      opacity: 1;
    }
  }

  &__icon {
    &--0 {
      animation: dropIn, dropOut;
      animation-duration: 250ms, 250ms;
      animation-iteration-count: 1, 1;
      animation-delay: 0ms, 750ms;
      animation-fill-mode: backwards, forwards;
      animation-timing-function: ease-out, ease-in;
    }
    &--1 {
      animation: dropIn;
      animation-duration: 250ms;
      animation-iteration-count: 1;
      animation-fill-mode: both;
      animation-timing-function: ease-out;
    }
  }
}

@import '../../../layout/bulma-vars.scss';

@function str-replace($_string, $search, $replace: '') {
  $string: #{$_string};
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}


//
// COLOR FUNCTIONS
// --------------------------------------------------

/*
 * Calculates the sRGB luma of a colour.
 *
 * Math nicked from a great Thoughtbot article by Reda Lemeden:
 * http://robots.thoughtbot.com/closer-look-color-lightness
 */

 @function luma($c) {
  $-local-red: red(rgba($c, 1.0));
  $-local-green: green(rgba($c, 1.0));
  $-local-blue: blue(rgba($c, 1.0));

  @return (0.2126 * $-local-red  +
           0.7152 * $-local-green +
           0.0722 * $-local-blue) / 255;
}

/*
 * Picks a colour from two options based on which one is more visible
 * on the given background colour.
 *
 * Usage: pick-visible-color($bg-color, $color-1, $color-2)
 */

 @function pick-visible-color($bg, $c1, $c2) {
   $bg-luma: luma($bg);
   $c1-luma: luma($c1);
   $c2-luma: luma($c2);

   $c1-diff: abs($bg-luma - $c1-luma);
   $c2-diff: abs($bg-luma - $c2-luma);

   @if $c1-diff > $c2-diff {
     @return $c1;
   } @else {
     @return $c2;
   }
 }

 @function pick-text-color($bg) {
   @return pick-visible-color($bg, white, black);
 }



@function ifLight($color, $yes, $no) {
  // @return $yes;
  @if (lightness($color) > 50) {
    @return $yes;
  } @else {
    @return $no;
  }
}

@function ifDark($color, $yes, $no) {
  // @return $yes;
  @if (lightness($color) <= 50) {
    @return $yes;
  } @else {
    @return $no;
  }
}

@function strip-units($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }

  @return $number;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.FAQ {
  $h-padding: 1em;
  $v-padding: 2em;
  $transition-in-duration: .4s;
  $transition-out-duration: .4s;

  width: 100%;

  &__list {
    border-bottom: 1px solid $grey;
    border-top: 1px solid $grey;
    margin: 0;
    padding: 0;
  }

  &__question {
    border-top: 1px solid $grey;
    padding: $v-padding 0;
    transition: all $transition-in-duration;
    transition-delay: $transition-out-duration;

    &:first-of-type {
      border-top: none;
    }

    &--active {
      background: $white;
      transition: all 0s;
    }

    &-button {
      $indicator-size: 1em;

      background-color: transparent;
      border: none;
      font-weight: bold;
      font-size: 1rem;
      margin: 0;
      overflow: hidden;
      padding: 0 ($indicator-size + 2 * $h-padding) 0 $h-padding;
      position: relative;
      text-align: left;
      width: 100%;
      cursor: pointer;

      &-state-indicator {
        position: absolute;
        height: $indicator-size;
        right: $h-padding;
        top: 0;
        width: $indicator-size;

        &::before,
        &::after {
          content: '';
          display: block;
          position: absolute;
          width: 100%;
          height: 2px;
          top: 50%;
          margin-top: -1px;
          background: #666;
          border-radius: 1px;
          transition: all $transition-in-duration;
        }

        &::after {
          transform: rotate(90deg);
        }

        &--active {
          &::after {
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  &__answer {
    background: $white;
    font-size: 1rem;
    transition: all $transition-out-duration ease-out;
    overflow: hidden;
    max-height: 0;

    &--active {
      max-height: 99em;
      transition: all $transition-in-duration ease-in;
    }

    &-content {
      opacity: 0;
      padding: 0 $h-padding $v-padding;
      transition: opacity $transition-in-duration;

      &--active {
        opacity: 1;
      }
    }
  }
}