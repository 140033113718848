$env: production;
@mixin browser-highlight($size: null, $style: null, $color: null) {
  // reference: https://ghinda.net/article/mimic-native-focus-css/

  @if not $style {
    outline-style: solid;

    @media (-webkit-min-device-pixel-ratio: 0) {
      outline-style: auto;
    }
  } @else {
    outline-style: $style;
  }

  @if not $size {
    outline-width: 2px;
  } @else {
    outline-width: $size;
  }

  @if not $color {
    outline-color: Highlight;

    @media (-webkit-min-device-pixel-ratio: 0) {
      outline-color: -webkit-focus-ring-color;
    }
  } @else {
    outline-color: $color;
  }
}

@mixin respond-above($breakpoint) {
  @media (min-width: $breakpoint) {
    @content;
  }
}

@mixin respond-above-x-or-y($breakpointX, $breakpointY) {
  @media (min-width: $breakpointX), (min-height: $breakpointY) {
    @content;
  }
}

@mixin respond-above-x-and-y($breakpointX, $breakpointY) {
  @media (min-width: $breakpointX) and (min-height: $breakpointY) {
    @content;
  }
}


//
//  RESPOND BELOW
//––––––––––––––––––––––––––––––––––––––––––––––––––

@mixin respond-below($breakpoint) {
  @media (max-width: ($breakpoint - 1)) {
    @content;
  }
}

@mixin respond-below-x-or-y($breakpointX, $breakpointY) {
  @media (max-width: $breakpointX - 1), (max-height: $breakpointY - 1) {
    @content;
  }
}

@mixin respond-below-x-and-y($breakpointX, $breakpointY) {
  @media (max-width: $breakpointX - 1) and (max-height: $breakpointY - 1) {
    @content;
  }
}


//
//  RESPOND BETWEEN
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include respond-between(sm, md) {}
@mixin respond-between($lower, $upper) {
  // Write the media query.
  @media (min-width: $lower) and (max-width: ($upper - 1)) {
    @content;
  }
}

@mixin modal-header {
  font-size: 1.5rem;
  line-height: 126%;
  /* or 30px */
  text-align: center;
  letter-spacing: -0.01em;
  color: #333333;
  margin: 0 auto;
}

@mixin confirm-btn {
  background-color: #2F2F30;
  color: #ffffff;
  border:1.5px solid #2F2F30;
}

@mixin confirm-btn-hover {
  background-color: #383c44;
  color: #ffffff;
  border-color: #2F2F30;
}

@mixin added-to-cart {
  text-decoration: none;
  color: $green !important;
  font-weight: 600;
  svg { height: 1.25em; }

  @keyframes dropOut {
    from {
      transform: translateY(0%);
      opacity: 1;
    }
    to {
      transform: translateY(100%);
      opacity: 0;
    }
  }
  @keyframes dropIn {
    from {
      transform: translateY(-100%);
      opacity: 0;
    }
    to {
      transform: translateY(0%);
      opacity: 1;
    }
  }

  &__icon {
    &--0 {
      animation: dropIn, dropOut;
      animation-duration: 250ms, 250ms;
      animation-iteration-count: 1, 1;
      animation-delay: 0ms, 750ms;
      animation-fill-mode: backwards, forwards;
      animation-timing-function: ease-out, ease-in;
    }
    &--1 {
      animation: dropIn;
      animation-duration: 250ms;
      animation-iteration-count: 1;
      animation-fill-mode: both;
      animation-timing-function: ease-out;
    }
  }
}

@import '../../../layout/bulma-vars.scss';

@function str-replace($_string, $search, $replace: '') {
  $string: #{$_string};
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}


//
// COLOR FUNCTIONS
// --------------------------------------------------

/*
 * Calculates the sRGB luma of a colour.
 *
 * Math nicked from a great Thoughtbot article by Reda Lemeden:
 * http://robots.thoughtbot.com/closer-look-color-lightness
 */

 @function luma($c) {
  $-local-red: red(rgba($c, 1.0));
  $-local-green: green(rgba($c, 1.0));
  $-local-blue: blue(rgba($c, 1.0));

  @return (0.2126 * $-local-red  +
           0.7152 * $-local-green +
           0.0722 * $-local-blue) / 255;
}

/*
 * Picks a colour from two options based on which one is more visible
 * on the given background colour.
 *
 * Usage: pick-visible-color($bg-color, $color-1, $color-2)
 */

 @function pick-visible-color($bg, $c1, $c2) {
   $bg-luma: luma($bg);
   $c1-luma: luma($c1);
   $c2-luma: luma($c2);

   $c1-diff: abs($bg-luma - $c1-luma);
   $c2-diff: abs($bg-luma - $c2-luma);

   @if $c1-diff > $c2-diff {
     @return $c1;
   } @else {
     @return $c2;
   }
 }

 @function pick-text-color($bg) {
   @return pick-visible-color($bg, white, black);
 }



@function ifLight($color, $yes, $no) {
  // @return $yes;
  @if (lightness($color) > 50) {
    @return $yes;
  } @else {
    @return $no;
  }
}

@function ifDark($color, $yes, $no) {
  // @return $yes;
  @if (lightness($color) <= 50) {
    @return $yes;
  } @else {
    @return $no;
  }
}

@function strip-units($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }

  @return $number;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.Swatch {
  $rectRatio: 1.5;
  $rectRatioSquat: 1.25;
  $squareRatio: 1;

  $edge: 1em;

  $rectW1: (189rem / strip-units($body-size));
  $rectH1: (280rem / strip-units($body-size));
  $rectW2: (130rem / strip-units($body-size));
  $rectH2: (192rem / strip-units($body-size));
  $rectW3: (82rem / strip-units($body-size));
  $rectH3: (103rem / strip-units($body-size));

  $squareW1: (180rem / strip-units($body-size));
  $squareH1: (180rem / strip-units($body-size));
  $squareW2: (135rem / strip-units($body-size));
  $squareH2: (135rem / strip-units($body-size));
  $squareW3: (103rem / strip-units($body-size));
  $squareH3: (103rem / strip-units($body-size));

  $suffixOverlay: '__overlay';
  $suffixTitles: '__titles';
  $suffixTag: '__tag';
  $suffixCta: '__cta';

  font-size: 1rem;
  color: inherit;
  display: flex;
  flex-direction: column;
  width: $rectW1;
  position: relative;
  line-height: 1.1;

  .Swatch__swatch {
    padding-top: $rectH1;
  }

  &--style-square {
    width: $squareW1;

    .Swatch__swatch {
      padding-top: $squareH1;
    }
  }

  &--is-clickable {
    cursor: pointer;
  }

  & > * {
    color: $black;
  }

  &--sz-sm {
    font-size: (14rem / strip-units($body-size));
    width: $rectW2;

    .Swatch__swatch {
      padding-top: $rectH2;
    }

    &.Swatch--style-square {
      width: $squareW2;

      .Swatch__swatch {
        padding-top: $squareH2;
      }
    }
  }

  &--sz-xs {
    font-size: (12rem / strip-units($body-size));
    width: $rectW3;

    .Swatch__swatch {
      padding-top: $rectH3;
    }

    &.Swatch--style-square {
      width: $squareW3;

      .Swatch__swatch {
        padding-top: $squareH3;
      }
    }
  }

  &--sz-xs,
  &--sz-sm {
    .Swatch#{$suffixCta}__btn {
      font-size: 0.8em;
    }
  }

  &--content-outside {
    .Swatch__insides {
      .Swatch#{$suffixTag} {
        top: $edge;
        bottom: unset;
      }
    }

    .Swatch#{$suffixTitles} {
      left: 0;
      margin-top: .25em;
      position: relative;
      right: auto;
      top: 0;
      width: auto;
      color: inherit;
    }
  }

  &--has-overlay {
    .Swatch__insides > *,
    .Swatch__outsides > *,
    .Swatch#{$suffixTitles} {
      opacity: 0.15;
      user-select: none;
      transition: opacity .2s ease-out;
    }
  }

  &#{$suffixTitles} {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: $edge;
    left: $edge;
    right: $edge;

    &--color-is-dark {
      color: $white;
    }

    &__title {
      line-height: 1.1;
      margin-bottom: .25em;
      word-break: break-word;
      hyphens: auto;
      font-size: inherit;

      &--number {
        order: -1;
        font-weight: 400;
      }
      &--name {
        font-weight: 600;
      }
    }
  }

  &__swatch {
    display: block;
    height: auto;
    width: 100%;
    position: relative;

    &--has-shadow {
      filter: drop-shadow(0 0.0625em 0.25em rgba(0,0,0,.2));
    }

    & > svg {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }

  &#{$suffixTag} {
    font-weight: 600;

    &__inner {
      word-break: normal;
      hyphens: none;
      font-size: (12rem / strip-units($body-size));
    }
  }

  &#{$suffixCta} {
    &__btn {
      align-items: center;
      background: none;
      border: none;
      color: inherit;
      cursor: pointer;
      display: flex;
      font-size: 1em;
      font-weight: 600;
      padding: 0;
      text-align: left;

      &__icon {
        color: inherit;
        display: inline-block;
        fill: currentColor;
        font-size: 1.125em;
        height: 1em;
        margin-right: 0.3em;
        stroke: currentColor;
        width: auto;
      }
    }
  }

  &#{$suffixOverlay} {
    align-items: center;
    color: inherit !important;
    display: flex;
    font-weight: 800;
    justify-content: center;
    opacity: 1 !important;
    padding: 1em;
    text-align: center;
    animation: fadeIn;
    animation-duration: .3s;
    animation-iteration-count: 1;
    animation-fill-mode: both;
  }

  &__insides {
    position: relative;

    &--color-is-dark {
      & > * {
        color: $white;
      }
    }

    .Swatch#{$suffixTag} {
      // the styles here ensure that basically all non-article words will be on separate lines
      position: absolute;
      left: $edge;
      bottom: $edge;
      height: auto;
      width: 3em;
    }

    .Swatch#{$suffixCta} {
      position: absolute;
      left: $edge;
      right: $edge;
      bottom: $edge;
    }
  }

  &__outsides {
    & > * {
      color: inherit;
    }

    .Swatch#{$suffixTag} {

    }

    .Swatch#{$suffixTag},
    .Swatch#{$suffixCta} {
      left: 0;
      margin-top: .25em;
      position: relative;
      right: auto;
      top: 0;
      width: auto;
    }

    .Swatch#{$suffixTag} {
      font-weight: normal;
    }
  }
}