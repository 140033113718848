$env: production;
@mixin browser-highlight($size: null, $style: null, $color: null) {
  // reference: https://ghinda.net/article/mimic-native-focus-css/

  @if not $style {
    outline-style: solid;

    @media (-webkit-min-device-pixel-ratio: 0) {
      outline-style: auto;
    }
  } @else {
    outline-style: $style;
  }

  @if not $size {
    outline-width: 2px;
  } @else {
    outline-width: $size;
  }

  @if not $color {
    outline-color: Highlight;

    @media (-webkit-min-device-pixel-ratio: 0) {
      outline-color: -webkit-focus-ring-color;
    }
  } @else {
    outline-color: $color;
  }
}

@mixin respond-above($breakpoint) {
  @media (min-width: $breakpoint) {
    @content;
  }
}

@mixin respond-above-x-or-y($breakpointX, $breakpointY) {
  @media (min-width: $breakpointX), (min-height: $breakpointY) {
    @content;
  }
}

@mixin respond-above-x-and-y($breakpointX, $breakpointY) {
  @media (min-width: $breakpointX) and (min-height: $breakpointY) {
    @content;
  }
}


//
//  RESPOND BELOW
//––––––––––––––––––––––––––––––––––––––––––––––––––

@mixin respond-below($breakpoint) {
  @media (max-width: ($breakpoint - 1)) {
    @content;
  }
}

@mixin respond-below-x-or-y($breakpointX, $breakpointY) {
  @media (max-width: $breakpointX - 1), (max-height: $breakpointY - 1) {
    @content;
  }
}

@mixin respond-below-x-and-y($breakpointX, $breakpointY) {
  @media (max-width: $breakpointX - 1) and (max-height: $breakpointY - 1) {
    @content;
  }
}


//
//  RESPOND BETWEEN
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include respond-between(sm, md) {}
@mixin respond-between($lower, $upper) {
  // Write the media query.
  @media (min-width: $lower) and (max-width: ($upper - 1)) {
    @content;
  }
}

@mixin modal-header {
  font-size: 1.5rem;
  line-height: 126%;
  /* or 30px */
  text-align: center;
  letter-spacing: -0.01em;
  color: #333333;
  margin: 0 auto;
}

@mixin confirm-btn {
  background-color: #2F2F30;
  color: #ffffff;
  border:1.5px solid #2F2F30;
}

@mixin confirm-btn-hover {
  background-color: #383c44;
  color: #ffffff;
  border-color: #2F2F30;
}

@mixin added-to-cart {
  text-decoration: none;
  color: $green !important;
  font-weight: 600;
  svg { height: 1.25em; }

  @keyframes dropOut {
    from {
      transform: translateY(0%);
      opacity: 1;
    }
    to {
      transform: translateY(100%);
      opacity: 0;
    }
  }
  @keyframes dropIn {
    from {
      transform: translateY(-100%);
      opacity: 0;
    }
    to {
      transform: translateY(0%);
      opacity: 1;
    }
  }

  &__icon {
    &--0 {
      animation: dropIn, dropOut;
      animation-duration: 250ms, 250ms;
      animation-iteration-count: 1, 1;
      animation-delay: 0ms, 750ms;
      animation-fill-mode: backwards, forwards;
      animation-timing-function: ease-out, ease-in;
    }
    &--1 {
      animation: dropIn;
      animation-duration: 250ms;
      animation-iteration-count: 1;
      animation-fill-mode: both;
      animation-timing-function: ease-out;
    }
  }
}

@import '../../../layout/bulma-vars.scss';

@function str-replace($_string, $search, $replace: '') {
  $string: #{$_string};
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}


//
// COLOR FUNCTIONS
// --------------------------------------------------

/*
 * Calculates the sRGB luma of a colour.
 *
 * Math nicked from a great Thoughtbot article by Reda Lemeden:
 * http://robots.thoughtbot.com/closer-look-color-lightness
 */

 @function luma($c) {
  $-local-red: red(rgba($c, 1.0));
  $-local-green: green(rgba($c, 1.0));
  $-local-blue: blue(rgba($c, 1.0));

  @return (0.2126 * $-local-red  +
           0.7152 * $-local-green +
           0.0722 * $-local-blue) / 255;
}

/*
 * Picks a colour from two options based on which one is more visible
 * on the given background colour.
 *
 * Usage: pick-visible-color($bg-color, $color-1, $color-2)
 */

 @function pick-visible-color($bg, $c1, $c2) {
   $bg-luma: luma($bg);
   $c1-luma: luma($c1);
   $c2-luma: luma($c2);

   $c1-diff: abs($bg-luma - $c1-luma);
   $c2-diff: abs($bg-luma - $c2-luma);

   @if $c1-diff > $c2-diff {
     @return $c1;
   } @else {
     @return $c2;
   }
 }

 @function pick-text-color($bg) {
   @return pick-visible-color($bg, white, black);
 }



@function ifLight($color, $yes, $no) {
  // @return $yes;
  @if (lightness($color) > 50) {
    @return $yes;
  } @else {
    @return $no;
  }
}

@function ifDark($color, $yes, $no) {
  // @return $yes;
  @if (lightness($color) <= 50) {
    @return $yes;
  } @else {
    @return $no;
  }
}

@function strip-units($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }

  @return $number;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.siriano-wrapper {
  margin: 0 auto;
  padding: 3rem;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  flex-direction: row-reverse;
  max-width: 1500px;
  @include respond-below($tablet) {
    padding: 4em 1em 0;
    flex-direction: column-reverse;
  }
}

.banner-image {
  max-width: 270px;
  position: relative;
  right: -7rem;
  top: 4px;
  @include respond-below($tablet) {
    max-width: 170px;
    top: 0;
    left: auto;
    right: auto;
  }
}

.siriano-callout {
  max-width: 1120px;
  width: 100%;
  box-sizing: border-box;
  border: 2px solid #DCDEDC;
  background: transparent;
  margin: 3.5625rem auto 3rem;
  display: flex;
  justify-content: flex-end;
  position: relative;
  left: -7rem;
  @include respond-below($tablet) {
    margin: 0 auto;
    flex-flow: column nowrap;
    justify-content: center;
    height: auto;
    left: auto;
    padding-top: 7rem;
    top: - 6rem;
    max-width: 80%;
    min-width: 300px;
  }

  .limited-tag {
    background: #D2E4F1;
    text-align: center;
    margin: -0.95em auto 0;
    border-radius: 3px;
    max-width: 171px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;
    color: #005896;
    text-transform: uppercase;
    padding: 3px 6px 4px;
    position: absolute;
    right: 3.3%;
    @include respond-below($tablet) {
      margin: 1.5em auto;
      max-width: 10em;
      position: relative;
      right: auto;
    }
  }

  .content-wrapper {
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    justify-content: space-between;
    padding: 1rem 0 1.7rem 15rem;
    @include respond-below($tablet) {
      padding: 0 0 1.7rem 0;
    }
  }

  .content-container {
    padding: 22px;
    max-width: 59%;
    @include respond-below($tablet) {
      max-width: 100%;
      padding-top: 0;
      text-align: center;
    }

    .banner-text {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: left;
      letter-spacing: -0.25px;
    }

    .banner-title {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 44px;
      line-height: 54px;
      text-align: left;
      letter-spacing: -0.015em;
      margin-bottom: 0.35em;
      @include respond-below($tablet) {
        font-size: 32px;
        line-height: 40px;
        text-align: center;
      }
    }
  }

  .button-container {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin: 1.3em 2em 0 0;
    min-width: 276px;
    width: 276px;

    .button, .banner-text {
      width: 100%;
      align-self: center;
    }

    @include respond-below($tablet) {
      margin: 0 auto;
    }
  }

  // *** NON-MOBILE MEDIA QUERIES *** //

  // Desktop, but smaller width
  @media screen and (min-width: 1024px) and (max-width: 1216px) {
    .content-container .banner-title {
      font-size: 32px;
      line-height: 40px;
    }
  }

  // Tablet only
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    min-width: 90%;
    .content-wrapper {
      .content-container {
        max-width: fit-content;
        .banner-title {
          font-size: 32px;
          line-height: 40px;
        }

        .banner-title, .banner-text {
          text-align: left;
        }
      }
    }
    .button-container {
      margin: 0 auto 1rem;
      width: 100%;
      padding: 0 22px;
    }
  }

  // Tablet and mobile
  @media screen and (max-width: 1024px) {
    .content-wrapper {
      flex-flow: column;
      justify-content: center;
    }
    .content-container {
      .banner-title {
        text-align: center;
        font-size: 32px;
        line-height: 40px;
      }
      .banner-text {
        text-align: center;
      }
    }
  }

  // Tiny mobile
  @media screen and (max-width: 600px) {
    max-width: 90%;
  }
}