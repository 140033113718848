$_body-size-no-unit: 16;
$body-size: $_body-size-no-unit * 1px;
$size-1: (42rem / $_body-size-no-unit);
$size-2: (36rem / $_body-size-no-unit);
$size-3: (30rem / $_body-size-no-unit);
$size-4: (25rem / $_body-size-no-unit);
$size-5: (20rem / $_body-size-no-unit);
$size-6: (16rem / $_body-size-no-unit);
$size-7: (13rem / $_body-size-no-unit);

$subtitle-size: $size-4;
$title-size: $size-3;

// One-off for iPadmini being a pixel smaller than any other tablet
$tablet-mini: 767px;

// Set your brand colors
$blue: #0069AF;
$red: #93324C;
$green: #53806C;
$teal: teal;
$white: #FFF;
$white-bis: #FAFAF9;
$grey-darker: #2b3038;
$grey-dark: #373A36;
$white-ter: #F0F0F0;
$dirty-grey: #E3E3DC;
$accent: #DCE2DF;
$yellow: #EDC167;
$danger: $red;

$scheme-main-bis: $white-bis;
$site-background: $white-bis;

$border: $grey-dark;

// Update Bulma's global variables
$family-sans-serif: "Open Sans", sans-serif;
$primary: $blue;
$info: $teal;
$link: $blue;
$dark: $grey-darker;
$text: $dark;

// sections
$section-padding-increment: 1.5rem;
$section-padding:	$section-padding-increment * 2 $section-padding-increment;
$section-padding-medium:	$section-padding-increment * 2 $section-padding-increment * 2;
$section-padding-large: $section-padding-increment * 6 $section-padding-increment * 2;

// Update some of Bulma's component variables
$body-background-color: $white;

$navbar-breakpoint: 0px;

// buttons
$button-padding-horizontal: .75em;
$button-hover-border-color: $border;
$button-background-color: $site-background;
$button-disabled-border-color: $border;
$button-disabled-opacity: .33;
$button-disabled-background-color: $white-ter;
$button-border-width: 1px;

// misc
$radius-small: 2px;
$radius: 2px;
$radius-large: 2px;

$input-radius: 4px;
$input-border-color: $grey-dark;
$input-hover-border-color: $input-border-color;

$input-disabled-border-color: $input-border-color;
$input-disabled-background-color: $white-ter;
$input-disabled-color: rgba($text, .5);

// modals
$modal-content-width: 796px;

$message-header-body-border-width: 1px;
$message-radius: $input-radius;
$message-body-radius: $input-radius;
$message-header-radius: $input-radius;
$message-header-weight: 600;

@import '~bulma/sass/utilities/_all.sass';
