$env: production;
@mixin browser-highlight($size: null, $style: null, $color: null) {
  // reference: https://ghinda.net/article/mimic-native-focus-css/

  @if not $style {
    outline-style: solid;

    @media (-webkit-min-device-pixel-ratio: 0) {
      outline-style: auto;
    }
  } @else {
    outline-style: $style;
  }

  @if not $size {
    outline-width: 2px;
  } @else {
    outline-width: $size;
  }

  @if not $color {
    outline-color: Highlight;

    @media (-webkit-min-device-pixel-ratio: 0) {
      outline-color: -webkit-focus-ring-color;
    }
  } @else {
    outline-color: $color;
  }
}

@mixin respond-above($breakpoint) {
  @media (min-width: $breakpoint) {
    @content;
  }
}

@mixin respond-above-x-or-y($breakpointX, $breakpointY) {
  @media (min-width: $breakpointX), (min-height: $breakpointY) {
    @content;
  }
}

@mixin respond-above-x-and-y($breakpointX, $breakpointY) {
  @media (min-width: $breakpointX) and (min-height: $breakpointY) {
    @content;
  }
}


//
//  RESPOND BELOW
//––––––––––––––––––––––––––––––––––––––––––––––––––

@mixin respond-below($breakpoint) {
  @media (max-width: ($breakpoint - 1)) {
    @content;
  }
}

@mixin respond-below-x-or-y($breakpointX, $breakpointY) {
  @media (max-width: $breakpointX - 1), (max-height: $breakpointY - 1) {
    @content;
  }
}

@mixin respond-below-x-and-y($breakpointX, $breakpointY) {
  @media (max-width: $breakpointX - 1) and (max-height: $breakpointY - 1) {
    @content;
  }
}


//
//  RESPOND BETWEEN
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include respond-between(sm, md) {}
@mixin respond-between($lower, $upper) {
  // Write the media query.
  @media (min-width: $lower) and (max-width: ($upper - 1)) {
    @content;
  }
}

@mixin modal-header {
  font-size: 1.5rem;
  line-height: 126%;
  /* or 30px */
  text-align: center;
  letter-spacing: -0.01em;
  color: #333333;
  margin: 0 auto;
}

@mixin confirm-btn {
  background-color: #2F2F30;
  color: #ffffff;
  border:1.5px solid #2F2F30;
}

@mixin confirm-btn-hover {
  background-color: #383c44;
  color: #ffffff;
  border-color: #2F2F30;
}

@mixin added-to-cart {
  text-decoration: none;
  color: $green !important;
  font-weight: 600;
  svg { height: 1.25em; }

  @keyframes dropOut {
    from {
      transform: translateY(0%);
      opacity: 1;
    }
    to {
      transform: translateY(100%);
      opacity: 0;
    }
  }
  @keyframes dropIn {
    from {
      transform: translateY(-100%);
      opacity: 0;
    }
    to {
      transform: translateY(0%);
      opacity: 1;
    }
  }

  &__icon {
    &--0 {
      animation: dropIn, dropOut;
      animation-duration: 250ms, 250ms;
      animation-iteration-count: 1, 1;
      animation-delay: 0ms, 750ms;
      animation-fill-mode: backwards, forwards;
      animation-timing-function: ease-out, ease-in;
    }
    &--1 {
      animation: dropIn;
      animation-duration: 250ms;
      animation-iteration-count: 1;
      animation-fill-mode: both;
      animation-timing-function: ease-out;
    }
  }
}

@import '../../../layout/bulma-vars.scss';

@function str-replace($_string, $search, $replace: '') {
  $string: #{$_string};
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}


//
// COLOR FUNCTIONS
// --------------------------------------------------

/*
 * Calculates the sRGB luma of a colour.
 *
 * Math nicked from a great Thoughtbot article by Reda Lemeden:
 * http://robots.thoughtbot.com/closer-look-color-lightness
 */

 @function luma($c) {
  $-local-red: red(rgba($c, 1.0));
  $-local-green: green(rgba($c, 1.0));
  $-local-blue: blue(rgba($c, 1.0));

  @return (0.2126 * $-local-red  +
           0.7152 * $-local-green +
           0.0722 * $-local-blue) / 255;
}

/*
 * Picks a colour from two options based on which one is more visible
 * on the given background colour.
 *
 * Usage: pick-visible-color($bg-color, $color-1, $color-2)
 */

 @function pick-visible-color($bg, $c1, $c2) {
   $bg-luma: luma($bg);
   $c1-luma: luma($c1);
   $c2-luma: luma($c2);

   $c1-diff: abs($bg-luma - $c1-luma);
   $c2-diff: abs($bg-luma - $c2-luma);

   @if $c1-diff > $c2-diff {
     @return $c1;
   } @else {
     @return $c2;
   }
 }

 @function pick-text-color($bg) {
   @return pick-visible-color($bg, white, black);
 }



@function ifLight($color, $yes, $no) {
  // @return $yes;
  @if (lightness($color) > 50) {
    @return $yes;
  } @else {
    @return $no;
  }
}

@function ifDark($color, $yes, $no) {
  // @return $yes;
  @if (lightness($color) <= 50) {
    @return $yes;
  } @else {
    @return $no;
  }
}

@function strip-units($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }

  @return $number;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.TopNav {
  $logo-padding: 1rem;

  position: sticky;
  top: 0;

  box-shadow: 0 -20px 20px 10px rgba(black, 0.5);
  z-index: 9999;

  .container {
    display: flex;
    justify-content: space-between;
  }

  .dropdown-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .cart-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .englishNavbar-item{
    border-right: 2px solid lightgrey;
    height: 37px;
    position: relative;
    right: 4%;
    top: 6px;
  }

  .nav-button-wrapper {
    display: flex;
    flex-direction: row;
    @include respond-below(401px) {
      flex-direction: column;
    }
  }

  @media (max-width: 600px) {
    .nav-btn {
      &.is-active {
        border-bottom: none !important;
      }
    }
    .webview {
      display: none;
    }

    .dropdownImg{
      height: 17px;
      display: inline-flex;
      position: relative;
      top: 4px;
      left: 10%;
    }

    .dropdownbtn.dropdown-item.is-active{
      background-color: transparent;
      color: #000;
    }

    .nav-button-wrapper {
      flex-direction: row;
    }

    .mobileview{
      display: flex;
      .dropdownOptions{
        background-color: $white;
        position: absolute;
        top: 90%;
        right: 4%;
      }

        .dropdown-item{
          padding-left: 4px !important;
          width: 70% !important;
        }

    }
  }
  @media (min-width: 601px) {
  .webview {
    display: flex;
  }

  .mobileview{
    display: none;
  }
}

  .navbar-brand {
    margin-left: -$logo-padding;

    @include respond-below($tablet) {
      margin-left: auto;
      margin-right: auto;
    }

    .navbar-item {
      padding-left: $logo-padding;
      padding-right: $logo-padding;
    }
  }

  .nav-btn {
    border: none;
    background: none;
    color: $text;
    opacity: 0.4;
    cursor: pointer;
    position: relative;
    top: 6px;
    padding-bottom: 10px;

    &:before {
      content: '';
      width: 32px;
      height: 20px;
      background-image: url('~src/images/ico-canada-flag.png');
      background-size: cover;
      background-position: center;
      margin: 0 6px 0 0;
      float: left;
    }

    &.is-active {
      opacity: 1;
      border-bottom: 2px solid;
    }


  }

  &__logo {
    font-size: (48em / strip-units($body-size));
    width: auto;
    max-height: 1em !important;

    @include respond-above($tablet) {
      font-size: (72em / strip-units($body-size));
    }
  }
}
