$env: production;
@mixin browser-highlight($size: null, $style: null, $color: null) {
  // reference: https://ghinda.net/article/mimic-native-focus-css/

  @if not $style {
    outline-style: solid;

    @media (-webkit-min-device-pixel-ratio: 0) {
      outline-style: auto;
    }
  } @else {
    outline-style: $style;
  }

  @if not $size {
    outline-width: 2px;
  } @else {
    outline-width: $size;
  }

  @if not $color {
    outline-color: Highlight;

    @media (-webkit-min-device-pixel-ratio: 0) {
      outline-color: -webkit-focus-ring-color;
    }
  } @else {
    outline-color: $color;
  }
}

@mixin respond-above($breakpoint) {
  @media (min-width: $breakpoint) {
    @content;
  }
}

@mixin respond-above-x-or-y($breakpointX, $breakpointY) {
  @media (min-width: $breakpointX), (min-height: $breakpointY) {
    @content;
  }
}

@mixin respond-above-x-and-y($breakpointX, $breakpointY) {
  @media (min-width: $breakpointX) and (min-height: $breakpointY) {
    @content;
  }
}


//
//  RESPOND BELOW
//––––––––––––––––––––––––––––––––––––––––––––––––––

@mixin respond-below($breakpoint) {
  @media (max-width: ($breakpoint - 1)) {
    @content;
  }
}

@mixin respond-below-x-or-y($breakpointX, $breakpointY) {
  @media (max-width: $breakpointX - 1), (max-height: $breakpointY - 1) {
    @content;
  }
}

@mixin respond-below-x-and-y($breakpointX, $breakpointY) {
  @media (max-width: $breakpointX - 1) and (max-height: $breakpointY - 1) {
    @content;
  }
}


//
//  RESPOND BETWEEN
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include respond-between(sm, md) {}
@mixin respond-between($lower, $upper) {
  // Write the media query.
  @media (min-width: $lower) and (max-width: ($upper - 1)) {
    @content;
  }
}

@mixin modal-header {
  font-size: 1.5rem;
  line-height: 126%;
  /* or 30px */
  text-align: center;
  letter-spacing: -0.01em;
  color: #333333;
  margin: 0 auto;
}

@mixin confirm-btn {
  background-color: #2F2F30;
  color: #ffffff;
  border:1.5px solid #2F2F30;
}

@mixin confirm-btn-hover {
  background-color: #383c44;
  color: #ffffff;
  border-color: #2F2F30;
}

@mixin added-to-cart {
  text-decoration: none;
  color: $green !important;
  font-weight: 600;
  svg { height: 1.25em; }

  @keyframes dropOut {
    from {
      transform: translateY(0%);
      opacity: 1;
    }
    to {
      transform: translateY(100%);
      opacity: 0;
    }
  }
  @keyframes dropIn {
    from {
      transform: translateY(-100%);
      opacity: 0;
    }
    to {
      transform: translateY(0%);
      opacity: 1;
    }
  }

  &__icon {
    &--0 {
      animation: dropIn, dropOut;
      animation-duration: 250ms, 250ms;
      animation-iteration-count: 1, 1;
      animation-delay: 0ms, 750ms;
      animation-fill-mode: backwards, forwards;
      animation-timing-function: ease-out, ease-in;
    }
    &--1 {
      animation: dropIn;
      animation-duration: 250ms;
      animation-iteration-count: 1;
      animation-fill-mode: both;
      animation-timing-function: ease-out;
    }
  }
}

@import '../../../layout/bulma-vars.scss';

@function str-replace($_string, $search, $replace: '') {
  $string: #{$_string};
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}


//
// COLOR FUNCTIONS
// --------------------------------------------------

/*
 * Calculates the sRGB luma of a colour.
 *
 * Math nicked from a great Thoughtbot article by Reda Lemeden:
 * http://robots.thoughtbot.com/closer-look-color-lightness
 */

 @function luma($c) {
  $-local-red: red(rgba($c, 1.0));
  $-local-green: green(rgba($c, 1.0));
  $-local-blue: blue(rgba($c, 1.0));

  @return (0.2126 * $-local-red  +
           0.7152 * $-local-green +
           0.0722 * $-local-blue) / 255;
}

/*
 * Picks a colour from two options based on which one is more visible
 * on the given background colour.
 *
 * Usage: pick-visible-color($bg-color, $color-1, $color-2)
 */

 @function pick-visible-color($bg, $c1, $c2) {
   $bg-luma: luma($bg);
   $c1-luma: luma($c1);
   $c2-luma: luma($c2);

   $c1-diff: abs($bg-luma - $c1-luma);
   $c2-diff: abs($bg-luma - $c2-luma);

   @if $c1-diff > $c2-diff {
     @return $c1;
   } @else {
     @return $c2;
   }
 }

 @function pick-text-color($bg) {
   @return pick-visible-color($bg, white, black);
 }



@function ifLight($color, $yes, $no) {
  // @return $yes;
  @if (lightness($color) > 50) {
    @return $yes;
  } @else {
    @return $no;
  }
}

@function ifDark($color, $yes, $no) {
  // @return $yes;
  @if (lightness($color) <= 50) {
    @return $yes;
  } @else {
    @return $no;
  }
}

@function strip-units($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }

  @return $number;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
// Small-ish sizes first
.dl-modal-content {
  font-family: "Open Sans", sans-serif;
  text-align: center;
  background-color: #FFFFFF;
  width: 327px;
  margin: 0 auto;
  padding: 24px;
}

.header-content .header-text {
  @include modal-header;
}

.color-preview {
  width: 30px;
  height: 30px;
  margin-right: .375rem;
  border: 1px solid rgba(0, 0, 0, 0.4);
  border-radius: 2px;
  box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.02), 0px 1px 4px -2px rgba(0, 0, 0, 0.08);
}

.item {
  display: flex;
  text-align: left;
  line-height: 0.9375rem;
  font-size: 0.75rem;
  padding: 0;
  background-color: white;
  min-width: 128px;
  width: 100%;
  margin: 0 auto 16px;
  @include respond-below($tablet) {
    padding: 0 0 0 1rem;
  }
}

.items {
  padding: 3rem 0 2rem;
}

.items-row {
  padding: 0 36px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  @include respond-below($tablet-mini) {
    flex-direction: column;
  }
}

.text-wrapper {
  height: auto;
  display: flex;
  flex-direction: column-reverse;
  &.is-dark {
    color:#fff;
  }
}

.item-title {
  font-weight: 400;
}

.description {
  font-weight: 600;
}

.color-preview {
  flex: none;
}

.header-wrapper {
  max-width: 85.3%;
  margin: 0 auto;
}

.header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  margin: 0 auto;
}

.heavier {
  font-weight: bold;
}

.confirm-wrapper {
  padding: 0;
}

.confirm-content {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  gap: 15px;
  padding-bottom: 0.63rem;
}

.confirm-content button {
  height: 2.6567rem;
  width:8.25rem;
  box-shadow: none;
  font-weight: 700;
  font-size: 1.0625rem;
  line-height: 1.5em;
  letter-spacing: 0.09375em;
  text-transform: uppercase;
  border-radius: 2px;
}

.confirmYesBtn {
  @include confirm-btn;
}

.confirmNoBtn {
  background-color: #FAFAF9;
  color: #2b3038;
  border: 1.5px solid #2F2F30;
}

/* Hovers */
.confirmYesBtn:hover {
  @include confirm-btn-hover
}

.confirmNoBtn:hover {
  background-color: #f0f0ef;
  color: #2F2F30;
  border-color: #2F2F30;
}

//***************************//
//****** MEDIA QUERIES ******//
//***************************//

@media screen and (max-width: 375px) {
  .App {
    width: 100%;
  }

  .confirmNoBtn, .confirmYesBtn {
    margin: 0 auto;
  }
}

// Not mobile
@media screen and (min-width: 768px) {
  .items {
    margin: 0 auto;
  }

  .items-row {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    width: 100%;
    margin-bottom: 1rem;
    height: auto;
    padding: 0;
  }

  .item, .item-swatch-wrapper {
    width: 8rem;
    display: flex;
    text-align: left;
    line-height: 1.125rem;
    font-size: 0.875rem;
    padding: 6px 8px;
    height: 12rem;
    margin: 0 16px 0 0;
    border-radius: 2px;
    filter:drop-shadow(0 0.0625em 0.25em rgba(0,0,0,0.2));
  }

  .item:last-child, .item-swatch-wrapper:last-child {
    margin-right: 0;
  }

  .color-preview {
    display: none;
    visibility: hidden;
  }

  .item-swatch-wrapper {
    position: relative;
    width: 8rem;
    padding: 0;
    height: 8rem;
  }

  .item-swatch-wrapper svg {
    width: 8rem;
    height: 8rem;
    position: absolute;
    top: 0;
    left: 0;
  }

  .item-swatch-desc-wrapper {
    position: relative;
    width: 128px;
    height: 128px;
    padding: 14px 14px;
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;

    @include respond-below($tablet) {
      position: relative;
      width: 128px;
      height: 128px;
      padding: 14px 14px;
      display: flex;
      flex-direction: column;
    }
  }

  .text-wrapper {
    display: flex;
    flex-direction: column;
    color: #2F2F30;
    height: 128px;
    position: fixed;
    top: 14px;
    left: 14px;
  }
}

// For tablet-like sizes
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .dl-modal-content {
    max-width: 660px;
    width: 100%;
    padding: 50px;
  }

  .item_swatch {
    height: 8rem;
    width: 8rem;
  }
}

// For desktoppy sizes
@media screen and (min-width: 1024px) {
  .dl-modal-content {
    max-width: 884px;
    width: 100%;
    padding: 90px;
  }

  .item_swatch {
    min-height: 11.25rem;
    min-width: 11.25rem;
  }
}

.siriano-header {
  font-weight: 600;
  font-size: 2rem;
  color: #333333;
  @include respond-below($tablet) {
    font-size: 1.375rem;
  }
}

